import React from "react"
import PropTypes from "prop-types"

import "../css/default.css"
import "../css/layout.css"
import "../css/print.css"

const Layout = ({ children }) => (
  <main>{children}</main>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
